<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <hr>
                <h3 class="text-uppercase text-center">
                    <em class="text-danger">{{ record.year }}</em>
                    Indexing Year's Students
                    <br>
                    <small class="text-uppercase text-center">
                        Current Allocated Quota -> {{ currentQuota }} <br>
                        Available Allocation Space -> {{ reminder }}
                    </small>
                </h3>
                
                <hr>

                <div class="col-lg-4" v-if="showChangeButton">
                        <base-button
                            type="danger" size="sm"
                            @click="changeStatus()"
                        >
                            <i class="fa fa-trash"></i> 
                            {{ applyText }}
                        </base-button><br>
                    </div>
            </div>

            <div class="col-lg-12" v-if="students && students.length > 0">
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="students"
                        >
                            <template slot="columns">
                                <th width="2%">
                                    <input
                                        class="mb-3 all-checked"
                                        type="checkbox"
                                        @change="updateRecords(null, true)"
                                    />
                                </th>
                                <th width="2%">S/N</th>
                                <th>Full Name <br> (surname last)</th>
                                <th>Gender</th>
                                <th>Matriculation no <br> / Phone no</th>
                                <th>Year of admission</th>
                                <th>Email Address</th>
                                <th width="5%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td>
                                <input
                                    class="mb-3 checkbox1"
                                    type="checkbox"
                                    :id="'other-check'+row.id"
                                    @change="updateRecords(row)"
                                />
                            </td>
                            <td class="budget">
                                {{ pagination.from+index+1}}
                            </td>
                            <td class="text-capitalize">
                                {{ getFullName(row) }}
                            </td>
                            <td class="text-capitalize">{{ row.gender ? row.gender : 'N/A' }}</td>
                            <td>
                                {{ row.matric_no ? row.matric_no : 'N/A' }} <br>
                                <badge type="primary">
                                    {{ row.phone ? row.phone : 'N/A' }}
                                </badge>
                            </td>
                            <td>{{ row.year ? row.year : 'N/A'  }}</td>
                            <td>{{ row.email ? row.email : 'N/A'  }}</td>
                            <td>
                                <base-button
                                    type="info" size="sm"
                                    @click="$emit('editRecord', row)"
                                >
                                    <i class="fa fa-pen"></i> Edit
                                </base-button>
                                <base-button
                                    type="danger" size="sm"
                                    @click="deleteRecord(row)"
                                >
                                    <i class="fa fa-trash"></i> Remove
                                </base-button>
                            </td>

                            </template>

                        </base-table>
                    </div>

                    <div class="col-lg-4" v-if="showChangeButton">
                        <base-button
                            type="danger" size="sm"
                            @click="changeStatus()"
                        >
                            <i class="fa fa-trash"></i> {{ applyText }}
                        </base-button><br>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination && ! showChangeButton"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"
                        >
                        </base-pagination>
                    </div>

                </div>
            </div>

            <!-- <div class="col-lg-12 text-center" v-if="students && students.length == 0">
                <base-alert type="warning">
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Students added yet!!!</h2>
                    </span>
                </base-alert>
            </div> -->
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';
    import Badge from '../../../components/Badge.vue';

    export default {
  components: { Badge },
        name: 'IndexingStudents',
        props: ["students", "pagination", "hasPagination", "paginate", "record", "checkYear", "currentQuota", 
                "reminder"],
        data() {
            return {
                applicants: [],
                showChangeButton: false,
                applyText: 'Remove Student'
            }
        },
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        },
        methods: {
            ...mapActions(['deleteIndexStudent']),
            updateRecords(applicant, status = false) {
                this.applyText = 'Remove Student'; 
                this.showChangeButton = false;
                this.checked = false
                if(status) {
                    this.applicants = [];// eslint-disable-next-line
                    if($('.all-checked').is(":checked")) {
                        this.applicants = this.students;
                        $('.checkbox1').each(function () {
                            this.checked = true;
                        });
                        this.showChangeButton = true;
                    } else {
                        $('.checkbox1').each(function () {
                            this.checked = false;
                        });
                        this.showChangeButton = false;
                    }   
                    this.applyText = 'Remove Students';                 
                } else {// eslint-disable-next-line
                    $('.all-checked').prop('checked', false);
                    let checkStatus = $('#other-check'+applicant.id).is(":checked")
                    if(checkStatus) {
                        this.applicants.push(applicant);
                        this.showChangeButton = true;
                    } else {
                        if(this.applicants.length > 0) {
                            this.applicants = this.applicants.filter(item => item.id !== applicant.id);
                        }
                        this.showChangeButton = false;
                    }
                }
            },
            changeStatus() {// approve application
                swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to proceed!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.deleteRecord(this.applicants, true);// make api call
                    }
                });
            },
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_names ? ' ' + record.other_names : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return 'N/A';
                }
            },
            deleteRecord(record, status = false) {// delete
                this.preloader();// show loading
                let data = [];
                if(status) {// multiple records
                    data = record;
                } else {// single record
                    data.push(record);
                }
                this.deleteIndexStudent(data).then((res) => {
                    this.applicants = [];
                    this.showChangeButton = false;
                    $('.all-checked').prop('checked', false);
                    $('.checkbox1').each(function () { this.checked = false; });
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                    }  
                    this.checkYear();                  
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.checkYear();
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>