<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/indexing"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <!-- import New Indexing template here -->
                            <NewIndexing
                                :changeTitle="changeTitle"
                                v-if="code && code == 'new-indexing'"
                            />

                            <!-- import Previous Indexing template here -->
                            <PreviousIndexing
                                :changeTitle="changeTitle"
                                v-if="code && code == 'previous-indexing-applications'"
                            />

                            <!-- import Search Indexing template here -->
                            <SearchIndexing
                                :changeTitle="changeTitle"
                                v-if="code && code == 'search-indexing-record'"
                            />

                            <!-- import Approved Indexing template here -->
                            <PreviousIndexing
                                :changeTitle="changeTitle"
                                status="approved"
                                v-if="code && code == 'approved-indexing-records'"
                            />
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import NewIndexing from './NewIndexing/NewIndexing';
    import PreviousIndexing from './PreviousIndexing/PreviousIndexing.vue';
    import SearchIndexing from './SearchIndexing/SearchIndexing.vue';

    export default {
        name: 'IndexingDetails',
        components: {
            NewIndexing,
            PreviousIndexing,
            SearchIndexing
        },
        data() {
        return {
            code: this.$route.params.code,
            title: null,
            isLoadingBlade: false
        }
        },
        methods: {
            loadTitle() {// load title
                return this.title = this.replaceString(this.code);
            },
            replaceString(value) {
                if(value) {
                    let text = value.replace('-', ' ');
                    return text.replace('-', ' ');
                }
                return 'Cpd Providers';
            },
            changeTitle(value, status) {// change title
                return status ? this.title = value : this.loadTitle();
            }
        },
        created() {
            this.loadTitle(); // load title
        }
    };
</script>
<style scoped>
</style>
