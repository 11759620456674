<template>
    <div>

        <div v-if="! showErrorStatus">
            <div class="row">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <hr>
                            <h3 class="text-uppercase text-center">
                                Edit
                                <em class="text-danger">{{ getFullName(record) }}</em>
                                's Student Record
                            </h3>
                            <hr>
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Surname"
                                placeholder="Enter Surname"
                                input-classes="form-control-alternative"
                                v-model="record.surname"
                                required
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="First Name"
                                placeholder="Enter First Name"
                                input-classes="form-control-alternative"
                                v-model="record.first_name"
                                required
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Other Names"
                                placeholder="Enter Other Names"
                                input-classes="form-control-alternative"
                                v-model="record.other_names"
                            />
                        </div>

                        <div class="col-lg-3">
                            <base-input alternative=""
                                label="Email Address"
                                placeholder="Enter Email Address"
                                input-classes="form-control-alternative"
                                v-model="record.email"
                            />
                        </div>

                        <div class="col-lg-3">
                            <base-input alternative=""
                                label="Gender"
                                required
                            >
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="record.gender"
                                >
                                    <option :value="null" disabled selected>--- Select One ---</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </base-input>
                        </div>

                        <div class="col-lg-3">
                            <base-input alternative=""
                                label="Matriculation Number"
                                placeholder="Enter Matriculation Number"
                                input-classes="form-control-alternative"
                                v-model="record.matric_no"
                                required
                            />
                        </div>

                        <div class="col-lg-3">
                            <base-input alternative=""
                                label="Year of Admission"
                                required
                            >
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="record.year"
                                >
                                    <option :value="null" disabled selected>--- Select One ---</option>
                                    <option
                                        v-for="year in  years"
                                        :key="year"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </base-input>
                        </div>

                        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus)'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--text">
                                    <h2 class="text-white">{{errorMsg}}</h2>
                                </span>
                            </base-alert>
                        </div>

                        <div class="col-lg-12">
                            <hr>
                            <base-button
                                type="success"
                                @click="updateStudent()"
                            >
                                <i class="fa fa-save"></i>
                                {{ isloading ? 'Please Wait...' : 'Update Student'}}
                            </base-button>

                            <base-button
                                type="danger"
                                class="right"
                                @click="$emit('editRecord', null)"
                            >
                                <i class="fa fa-times"></i> Cancel
                            </base-button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';

    export default {
        name: 'EditIndexingStudent',
        props: ['data', 'updateRecords'],
        computed: {
            years() {
                let startYear = moment().format('YYYY') - 122;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        data() {
            return {
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    surname: null,
                    first_name: null,
                    other_names: null,
                    gender: null,
                    matric_no: null,
                    year: null,
                    email: null
                }
            }
        },
        methods: {
            ...mapActions(['updateIndexingStudent']),
            setData() {
                let student = this.data;
                if(student) {
                    this.record = {
                        surname: student.surname,
                        first_name: student.first_name,
                        other_names: student.other_names,
                        gender: student.gender,
                        matric_no: student.matric_no,
                        year: student.year,
                        email: student.email,
                        id: student.id
                    };
                }
            },
            updateStudent() {// update
                this.isloading = true;
                this.preloader();// show loading
                this.updateIndexingStudent(this.record).then((res) => {
                    this.isloading = false;
                    if(res.status) {
                        this.updateRecords(res.student);
                        swal.fire("Record Updated!", res.msg, "success");
                    } else {
                        swal.fire("Error Ocurred!", res.msg, "warning");
                        this.setData();
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                    this.setData();
                });
            },
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_names ? ' ' + record.other_names : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return 'N/A';
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>