<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12" v-if="records && records.length > 0">
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="records"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th>Indexing Year</th>
                                <th>Status</th>
                                <th>Application Date</th>
                                <th>Approved / <br> Rejected Date</th>
                                <th width="5%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td>{{ row.year ? row.year : 'N/A'  }}</td>
                            <td>
                                <badge :type="getColor(row.status)">
                                    {{ row.status ? row.status : 'N/A'  }}
                                </badge>
                            </td>
                            <td>{{ row.application_date | getDateFormat }}</td>
                            <td>{{ row.approved_date | getDateFormat }}</td>
                            <td>
                                <router-link
                                    :to="'/indexing/application-details/'+row.id"
                                    class="btn btn-sm btn-success"
                                >
                                    <i class="fa fa-eye"></i> Details
                                </router-link>
                            </td>

                            </template>

                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Badge from '../../../components/Badge.vue';

    export default {
        components: { Badge },
        name: 'IndexingData',
        props: ["records", "pagination", "hasPagination", "paginate", "record", "checkYear"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('D MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getColor(status) {
                if(status == 'submitted') {
                    return 'warning';
                } else if(status == 'approved') {
                    return 'success';
                } else {
                    return 'danger';
                }
            }
        }
    }
</script>