<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <div class="row" v-if="! hasBulkUpload">
                <div class="container" v-if="! showAddPage">
                    <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-6 text-center">
                            <base-input alternative=""
                                label="Select Indexing Year"
                                required
                            >
                                <select
                                    class="form-control form-control-alternative text-center"
                                    v-model="record.year"
                                    @change="checkYear()"
                                >
                                    <option :value="null" disabled selected>--- Select One ---</option>
                                    <option
                                        v-for="year in  years"
                                        :key="year"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </base-input>
                        </div>
                    </div>
                </div>

                <div 
                    class="container text-center" 
                    v-if="showAddPage && ! showEditPage && ! quotaStatus && hasRecords && ! isLoadingBlade"
                >
                    <hr>
                    <base-alert type="warning">
                        <span class="alert-inner--text">
                            <h2 class="text-white text-uppercase">
                                Allocated quota has been exhausted for the Indexing year
                                <em>{{ record.year }}</em>
                            </h2>
                        </span>
                    </base-alert>

                    <base-button
                        type="danger"
                        class="right"
                        v-if="! showAddPage"
                        @click="hideAddPage()"
                    >
                        <i class="fa fa-times"></i> Back
                    </base-button>
                </div>

                <IndexingStudents
                    :record="record"
                    :students="students"
                    :pagination="pagination"
                    :hasPagination="hasPagination"
                    :paginate="paginate"
                    :checkYear="checkYear"
                    :currentQuota="current_quota"
                    :reminder="reminder"
                    v-on:editRecord="editRecord"
                    v-if="showAddPage && ! showEditPage && ! isLoadingBlade"
                />

                <div class="col-lg-12" v-if="showAddPage && ! showEditPage && hasRecords && ! isLoadingBlade">
                    <hr>
                    <base-button
                        type="primary"
                        :disabled="! quotaStatus"
                        @click="editRecord()"
                    >
                        <i class="fa fa-save"></i> Add Students
                    </base-button>

                    <base-button
                        type="success"
                        @click="submitList()"
                    >
                        <i class="fa fa-upload"></i>
                        {{ isloading ? 'Please Wait...' : 'Submit List'}}
                    </base-button>

                    <base-button
                        type="danger"
                        class="right"
                        @click="hideAddPage()"
                    >
                        <i class="fa fa-times"></i> Cancel
                    </base-button>
                </div>

                <div class="container" v-if="(! hasRecords || showEditPage) && ! isLoadingBlade">
                    <div 
                        class="row" 
                        v-if="(showAddPage && (quotaStatus || (! hasRecords && ! quotaStatus)) && hasSpace) 
                            || showEditPage"
                    >
                        <div class="col-lg-12" v-if="showEditPage">
                            <h3 class="text-uppercase text-center">
                                {{ record.student && record.student.id ? 'Edit Student of Indexing Year' :
                                    'Add Students to Indexing Year' }}
                                <em class="text-danger">{{ record.year }}</em>
                                <br>
                                <small class="text-uppercase text-center">
                                    Current Allocated Quota -> {{ current_quota }} <br>
                                    Available Allocation Space -> {{ reminder }}
                                </small>
                            </h3>
                            <hr>
                        </div>

                        <div class="col-lg-12" v-if="! showEditPage">
                            <h4>
                                For Bulk Upload of Students, do the following: <br>
                                i.  Kindly click on <em class="text-primary">Sample file</em> button below to 
                                download a Sample file (Excel format). <br>
                                ii. Populate the Sample file accordingly. <br>
                                iii. Click on <em class="text-success">Upload file</em> button below to upload 
                                Students
                            </h4>
                        </div>
                        
                        <div class="col-lg-2" v-if="! showEditPage">
                            <form :action="getRootUrl+'/export-school-indexing-sample'" method="POST">
                                
                                <input type="hidden" name="type" value="indexing" />

                                <button
                                    class="btn btn-lg btn-primary"
                                    nativeType="submit"
                                >
                                    <i class="fa fa-file-export"></i>
                                    Sample file
                                </button>
                            </form>
                        </div>

                        <div class="col-lg-10" v-if="! showEditPage">
                            <input
                                type="file"
                                ref="photo"
                                style="display: none"
                                @change="onFileSelected"
                            >

                            <button
                                class="btn btn-lg btn-success"
                                @click="$refs.photo.click()"
                            >
                                <i class="fa fa-upload"></i>
                                Upload file
                            </button>
                        </div>
                        
                        <AddStudent 
                            :record="record"
                            :years="years"
                            :tel="tel"
                            :updatePhoneNumber="updatePhoneNumber"
                            :showEditPage="showEditPage"
                        />

                        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--text">
                                    <h2 class="text-white">{{errorMsg}}</h2>
                                </span>
                            </base-alert>
                        </div>

                        <div class="col-lg-12">
                            <hr>
                            <base-button
                                type="success"
                                @click="saveStudent()"
                            >
                                <i class="fa fa-save"></i>
                                {{ isloading ? 'Please Wait...' : saveButtonText }}
                            </base-button>

                            <base-button
                                type="danger"
                                class="right"
                                @click="hideAddPage()"
                            >
                                <i class="fa fa-times"></i> Cancel
                            </base-button>
                        </div>
                    </div>

                    <div 
                        class="row" 
                        v-if="showAddPage && ! showEditPage && (quotaStatus || (! hasRecords && ! quotaStatus)) 
                                && ! hasSpace && ! isLoadingBlade"
                    >
                        <div class="col-lg-12 text-center">
                            <base-alert type="warning">
                                <span class="alert-inner--text">
                                    <h2 class="text-white">
                                        Quota has been exhausted, no more space to add student(s)!!!
                                    </h2>
                                </span>
                            </base-alert>
                        </div>

                        <div class="col-lg-12 text-center">
                            <base-button
                                type="danger"
                                size="lg"
                                @click="hideAddPage()"
                            >
                                <i class="fa fa-times"></i> Go Back
                            </base-button>
                        </div>
                    </div>
                </div>

            </div>

            <BulkStudentsUpload 
                :indexing="record"
                :currentQuota="current_quota"
                :records="sheetRecords"
                :loadPhoneNumber="loadPhoneNumber"
                :years="years"
                :checkYear="checkYear"
                :removeBulkUpload="removeBulkUpload"
                v-if="hasBulkUpload && ! showEditPage"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import IndexingStudents from './IndexingStudents.vue';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import * as XLSX from "xlsx";
    import BulkStudentsUpload from './BulkStudentsUpload.vue'
    import AddStudent from './AddStudent.vue';

    export default {
        name: 'NewIndexing',
        components: {
            IndexingStudents,
            VuePhoneNumberInput,
            BulkStudentsUpload,
            AddStudent
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl']),
            years() {
                let startYear = 2022;// start from 1900
                let currentYear = parseInt(moment().format('YYYY')) + 1;
                let years = [];
                for (let i = startYear; i <= currentYear; i++) {
                    years.push(i);
                }
                return years;
            },
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.students && this.students.length > 0;
            },
            saveButtonText() {
                let text = this.record.student && this.record.student.id ? 'Update' : 'Add';
                text += ' Student';
                return text;
            },
            hasSpace() {// check if there is space
                return this.current_quota > this.total_students;
            },
            reminder() {// get reminder of the allocation
                if(this.current_quota) {
                    return Math.abs(parseInt(this.current_quota) - parseInt(this.total_students));
                } else {
                    return 'N/A';
                }
            },
            hasBulkUpload() {// has bulk uploaded students
                return this.sheetRecords && this.sheetRecords.length > 0;
            }
        },
        data() {
            return {
                isLoadingBlade: false,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    year: null,
                    student: {
                        surname: null,
                        first_name: null,
                        other_names: null,
                        gender: null,
                        matric_no: null,
                        year: null,
                        email: null,
                        phone: null,
                        phone_code: null
                    }
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                paginatedItems: [],
                students: [],
                showAddPage: false,
                showEditPage: false,
                quotaStatus: false,
                tel: {
                    phone: null,
                    code: 'NG'
                },
                current_quota: 0,
                total_students: 0,
                sheetRecords: []
            }
        },
        methods: {
            ...mapActions(['getIndexingYear', 'saveIndexing', 'submitIndexStudentsList']),
            removeBulkUpload() {// remove bulk upload page
                return this.sheetRecords = [];
            },
            onFileSelected(event) {
                this.isLoadingBlade = true;
                let status =  this.validateFile(event.target.files[0]);
                return status ? this.uploadExcelFile(event) : false;
            },
            uploadExcelFile(event) {
                                this.file = event.target.files[0];
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(this.file);
                fileReader.onload = (e) => {
                    this.arrayBuffer = fileReader.result;
                    var data = new Uint8Array(this.arrayBuffer);
                    var arr = new Array();
                    for (var i = 0; i != data.length; ++i)
                    arr[i] = String.fromCharCode(data[i]);
                    var bstr = arr.join("");
                    var workbook = XLSX.read(bstr, { type: "binary" });
                    var first_sheet_name = workbook.SheetNames[0];
                    var worksheet = workbook.Sheets[first_sheet_name];
                    // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
                    this.sheetRecords = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                    this.isLoadingBlade = false;
                };
            },
            validateFile(file) {
                const validFileTypes = ['xlsx'];// valid image format array
                var fileExt = file.name.split('.').pop().toLowerCase();
                var validExt = validFileTypes.includes(fileExt);
                let fileSize = file.size;// get selected file size
                if(validExt && fileSize <= 2000000) {// for valid file and size
                    return true;
                } else if(! validExt) {// invalid file
                    swal.fire("Invalid File!", "Only Excel (xlsx) file are accepted", "warning");
                    return false;
                } else if(fileSize > 2000000) {// big file
                    swal.fire("File is too large!", "File should be less than 2MB", "warning");
                    return false;
                }
            },
            loadPhoneNumber(student) {// update phone no
                let data = student.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.student.phone = event.formattedNumber;
                    this.record.student.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            checkYear() {// check indexing year
                this.showEditPage = false;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                this.getIndexingYear({ year : this.record.year}).then((res) => {
                    if(res.status) {
                        res.indexing ? this.setData(res.indexing) : false;// set data
                        this.showAddPage = true;
                        swal.close();
                    } else {
                        swal.fire("Error Ocurred!", res.msg, "warning");
                        this.record.year = null,
                        this.emptyFields();
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                    this.record.year = null,
                    this.emptyFields();
                });
            },
            setData(indexing) {// set paginated function
                this.emptyFields();
                this.record.year = indexing.year;
                this.record.id = indexing.id;
                this.quotaStatus = indexing.quota_status;
                this.current_quota = indexing.training_school ? indexing.training_school.quota: 0;// get quota                
                if(indexing && indexing.students) {
                    this.total_students = indexing.students.length;// get total no of students
                    let res = indexing.students;
                    this.students = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        for (let index = 1; index <= perPage; index++) {
                            this.students.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.students = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.students = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.students = this.paginatedItems.slice(from, to);
            },
            saveStudent() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    let data = this.record;
                    data.training_school_id = this.$store.state.user.school.id;
                    this.saveIndexing(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Indexing Updated!", res.msg, "success");
                            this.checkYear();
                        } else {
                            this.hideAddPage();
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            validatedData() {
                let data = this.record.student;
                data.year != null ? data.year : this.showInuptError('Year of Admission');
                data.matric_no != null ? data.matric_no : this.showInuptError('Matriculation No');
                data.gender != null ? data.gender : this.showInuptError('Gender');
                data.first_name != null ? data.first_name : this.showInuptError('First Name');
                data.surname != null ? data.surname : this.showInuptError('Surname');
                let status = data.surname && data.first_name && data.gender && data.matric_no && data.year;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            hideAddPage() {
                this.record.year = null,
                this.emptyFields();
                this.showEditPage = false;
                return this.showAddPage = ! this.showAddPage;
            },
            emptyFields() {
                this.record.student = {
                    surname: null,
                    first_name: null,
                    other_names: null,
                    gender: null,
                    matric_no: null,
                    year: null,
                    email: null,
                    phone: null,
                    phone_code: null
                };
                this.tel = {
                    phone: null,
                    code: 'NG'
                };
                this.students = [];
                this.paginatedItems = [];
                this.showErrorStatus = false;
                this.showInputErrorStatus = false;
                this.isLoadingBlade = false;
            },
            editRecord(row) {
                this.emptyFields();
                this.showEditPage = true;
                if(row) { 
                    this.record.student = row; 
                    this.loadPhoneNumber(row);// load phone no
                }
            },
            submitList() {// submit application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.submitListApiCall();// make api call
                    }
                });
            },
            submitListApiCall() {// delete
                this.showErrorStatus = false;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let id = this.record && this.record.id ? this.record.id : false;
                if(id) {
                    this.submitIndexStudentsList(id).then((res) => {                        
                        if(res.status) {
                            swal.fire("List Submitted!", res.msg , "success");
                            // setTimeout(() => {
                            //     window.location.href = '/indexing';
                            // }, 2000);
                            this.showEditPage = false;                            
                            this.showAddPage = false;
                            this.record.year = null;
                        } else {
                            this.checkYear();
                            swal.fire("Error Occurred!", "An error occurred while proccessing, try again", "warning");
                        }
                    }).
                    catch(() => {
                        swal.fire("Network Failure", "Reload page and try again...", "warning");
                    });
                } else {
                    swal.fire("Error Occurred", "Reload page and try again...", "warning");
                    this.checkYear();
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>