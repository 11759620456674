<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <div class="row">
                <div class="container" v-if="! showRecord">
                    <div class="row">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4 text-center">
                            <base-input alternative=""
                                label="Student Matriculation Number"
                                placeholder="Enter Student Matriculation Number"
                                input-classes="form-control-alternative"
                                v-model="matric_no"
                                @keyup.enter="getStudent()"
                                required
                            />

                            <base-button
                                    type="info" size="lg"
                                    @click="getStudent()"
                                >
                                <i class="fa fa-search"></i>
                                {{ isloading ? 'Please Wait...' : 'Find Student' }}
                            </base-button>
                        </div>
                    </div>
                </div>

                <IndexingStudentData
                    :students="students"
                    v-on:editRecord="editRecord"
                    v-if="showRecord && ! showEditPage && ! isLoadingBlade"
                />

                <EditIndexingStudent
                    :data="record"
                    :updateRecords="updateRecords"
                    v-on:editRecord="editRecord"
                    v-if="showRecord && showEditPage && ! isLoadingBlade"
                />

                <div class="col-lg-12" v-if="showRecord && ! showEditPage">
                    <hr>
                    <base-button
                        type="danger"
                        class="right"
                        @click="hideProfile()"
                    >
                        <i class="fa fa-times"></i> Close Profile
                    </base-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import IndexingStudentData from './IndexingStudentData.vue';
    import EditIndexingStudent from './EditIndexingStudent.vue';

    export default {
        name: 'SearchIndexing',
        components: {
            IndexingStudentData,
            EditIndexingStudent
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            years() {
                let startYear = moment().format('YYYY') - 122;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        data() {
            return {
                isLoadingBlade: false,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                matric_no: null,
                record: {},
                students: [],
                showRecord: false,
                showEditPage: false
            }
        },
        methods: {
            ...mapActions(['searchIndexingStudent']),
            hideProfile() {
                this.showRecord = false;
                this.showEditPage = false;
                this.emptyFields();
            },
            getStudent() {// check indexing year
                if(this.matric_no) {
                    this.showEditPage = false;
                    this.showInputErrorStatus = false;
                    this.preloader();// show loading
                    this.searchIndexingStudent({ matric_no : this.matric_no }).then((res) => {
                        if(res.status) {
                            this.updateRecords(res.student);
                            this.showRecord = true;
                            swal.close();
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                            this.emptyFields();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                        this.emptyFields();
                    });
                } else {
                    let msg = "Invalid Matriculation number, check and try again";
                    swal.fire("Error Ocurred!", msg, "warning");
                    this.emptyFields();
                }
            },
            updateRecords(student) {
                this.students = [];
                this.record = student;
                this.students.push(student);
                this.showEditPage = false;
            },
            editRecord(data) {
                if(data) { this.record = data; }
                this.showEditPage = ! this.showEditPage;
            },
            emptyFields() {
                this.matric_no = null;
                return this.record = {};
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>