<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-uppercase text-center" v-if="! showEditPage">
                <hr>
                <h3>Add Student to Indexing List</h3>
                <hr>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Surname"
                    placeholder="Enter Surname"
                    input-classes="form-control-alternative"
                    v-model="record.student.surname"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="First Name"
                    placeholder="Enter First Name"
                    input-classes="form-control-alternative"
                    v-model="record.student.first_name"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Other Names"
                    placeholder="Enter Other Names"
                    input-classes="form-control-alternative"
                    v-model="record.student.other_names"
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Email Address"
                    placeholder="Enter Email Address"
                    input-classes="form-control-alternative"
                    v-model="record.student.email"
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Phone Number"
                    placeholder="Phone Number"
                    input-classes="form-control-alternative"
                >
                <!-- v-model="profile.phone"  -->
                    <VuePhoneNumberInput
                        v-model="tel.phone"
                        @update="updatePhoneNumber"
                        :default-country-code="tel.code"
                        :no-example="true"
                        :clearable="true"
                    />
                </base-input>
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Gender"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.student.gender"
                    >
                        <option :value="null" disabled selected>--- Select One ---</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </base-input>
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Matriculation Number"
                    placeholder="Enter Matriculation Number"
                    input-classes="form-control-alternative"
                    v-model="record.student.matric_no"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Year of Admission"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.student.year"
                    >
                        <option :value="null" disabled selected>--- Select One ---</option>
                        <option
                            v-for="year in  years"
                            :key="year"
                            :value="year"
                        >
                            {{ year }}
                        </option>
                    </select>
                </base-input>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        name: 'AddStudent',
        props: ['record', 'years', 'tel', 'updatePhoneNumber', 'showEditPage'],
        components: {
            VuePhoneNumberInput
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>