<template>
<div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/indexing/previous-indexing-applications"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && ! showErrorStatus">
                            <div class="row">

                                <div class="container text-center" v-if="showAddPage && ! quotaStatus && hasRecords && ! isLoadingBlade">
                                    <hr>
                                    <base-alert type="warning">
                                        <span class="alert-inner--text">
                                            <h2 class="text-white text-uppercase">
                                                Allocated quota has been exhausted for the Indexing year
                                                <em>{{ record.year }}</em>
                                            </h2>
                                        </span>
                                    </base-alert>
                                </div>

                                <IndexingStudents
                                    :record="record"
                                    :students="students"
                                    :pagination="pagination"
                                    :hasPagination="hasPagination"
                                    :paginate="paginate"
                                    :checkYear="checkYear"
                                    :currentQuota="current_quota"
                                    :reminder="reminder"
                                    v-on:editRecord="editRecord"
                                    v-if="showAddPage && ! showEditPage && ! isLoadingBlade"
                                />

                                <div class="col-lg-12" v-if="showAddPage && ! showEditPage && hasRecords && ! isLoadingBlade">
                                    <hr>
                                    <base-button
                                        type="primary"
                                        @click="editRecord()"
                                        :disabled="! quotaStatus"
                                    >
                                        <i class="fa fa-save"></i> Add Students
                                    </base-button>

                                    <base-button
                                        type="success"
                                        class="right"
                                        @click="submitList()"
                                    >
                                        <i class="fa fa-upload"></i>
                                        {{ isloading ? 'Please Wait...' : 'Re-Submit List'}}
                                    </base-button>
                                </div>


                                <div class="container" v-if="(! hasRecords || showEditPage) && ! isLoadingBlade">
                                    <div class="row" v-if="showAddPage && (quotaStatus || (! hasRecords && ! quotaStatus))">
                                        <div class="col-lg-12">
                                            <hr>
                                            <h3 class="text-uppercase text-center">
                                                {{ record.student && record.student.id ? 'Edit Students of Indexing Year' :
                                                    'Add Students to Indexing Year' }}
                                                <em class="text-danger">{{ record.year }}</em>
                                            </h3>
                                            <hr>
                                        </div>

                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                label="Surname"
                                                placeholder="Enter Surname"
                                                input-classes="form-control-alternative"
                                                v-model="record.student.surname"
                                                required
                                            />
                                        </div>

                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                label="First Name"
                                                placeholder="Enter First Name"
                                                input-classes="form-control-alternative"
                                                v-model="record.student.first_name"
                                                required
                                            />
                                        </div>

                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Other Names"
                                                placeholder="Enter Other Names"
                                                input-classes="form-control-alternative"
                                                v-model="record.student.other_names"
                                            />
                                        </div>

                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Email Address"
                                                placeholder="Enter Email Address"
                                                input-classes="form-control-alternative"
                                                v-model="record.student.email"
                                            />
                                        </div>

                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Phone Number"
                                                placeholder="Phone Number"
                                                input-classes="form-control-alternative"
                                            >
                                            <!-- v-model="profile.phone"  -->
                                                <VuePhoneNumberInput
                                                    v-model="tel.phone"
                                                    @update="updatePhoneNumber"
                                                    :default-country-code="tel.code"
                                                    :no-example="true"
                                                    :clearable="true"
                                                />
                                            </base-input>
                                        </div>

                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Gender"
                                                required
                                            >
                                                <select
                                                    class="form-control form-control-alternative"
                                                    v-model="record.student.gender"
                                                >
                                                    <option :value="null" disabled selected>--- Select One ---</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </base-input>
                                        </div>

                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Matriculation Number"
                                                placeholder="Enter Matriculation Number"
                                                input-classes="form-control-alternative"
                                                v-model="record.student.matric_no"
                                                required
                                            />
                                        </div>

                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Year of Admission"
                                                required
                                            >
                                                <select
                                                    class="form-control form-control-alternative"
                                                    v-model="record.student.year"
                                                >
                                                    <option :value="null" disabled selected>--- Select One ---</option>
                                                    <option
                                                        v-for="year in  years"
                                                        :key="year"
                                                        :value="year"
                                                    >
                                                        {{ year }}
                                                    </option>
                                                </select>
                                            </base-input>
                                        </div>

                                        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
                                            <base-alert :type="errorType">
                                                <span class="alert-inner--text">
                                                    <h2 class="text-white">{{errorMsg}}</h2>
                                                </span>
                                            </base-alert>
                                        </div>

                                        <div class="col-lg-12">
                                            <hr>
                                            <base-button
                                                type="success"
                                                @click="saveStudent()"
                                            >
                                                <i class="fa fa-save"></i>
                                                {{ isloading ? 'Please Wait...' : saveButtonText +' Student'}}
                                            </base-button>

                                            <base-button
                                                type="danger"
                                                class="right"
                                                @click="hideAddPage()"
                                            >
                                                <i class="fa fa-times"></i> Cancel
                                            </base-button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import IndexingStudents from './NewIndexing/IndexingStudents.vue';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        name: 'PendingIndexing',
        components: {
            IndexingStudents,
            VuePhoneNumberInput
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            years() {
                let startYear = moment().format('YYYY') - 122;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.students && this.students.length > 0;
            },
            saveButtonText() {
                return this.record && this.record.id ? 'Update' : 'Add';
            },
            title() {
                let title = 'Edit details for indexing year <em class="text-danger">';
                title += this.record.year +'</em>';
                return title;
            },
            reminder() {// get reminder of the allocation
                if(this.current_quota) {
                    return Math.abs(parseInt(this.current_quota) - parseInt(this.total_students));
                } else {
                    return 'N/A';
                }
            }
        },
        data() {
            return {
                isLoadingBlade: false,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    year: this.$route.params.year,
                    student: {
                        surname: null,
                        first_name: null,
                        other_names: null,
                        gender: null,
                        matric_no: null,
                        year: null,
                        email: null,
                        phone: null,
                        phone_code: null
                    }
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                paginatedItems: [],
                students: [],
                showAddPage: false,
                showEditPage: false,
                quotaStatus: false,
                tel: {
                    phone: null,
                    code: 'NG'
                },
                current_quota: 0,
                total_students: 0
            }
        },
        methods: {
            ...mapActions(['getIndexingYear', 'saveIndexing', 'submitIndexStudentsList']),
            loadPhoneNumber(student) {// update phone no
                let data = student.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.student.phone = event.formattedNumber;
                    this.record.student.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            checkYear() {// check indexing year
                this.showEditPage = false;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                this.getIndexingYear({ year : this.record.year, option : true}).then((res) => {
                    if(res.status) {
                        res.indexing ? this.setData(res.indexing) : false;// set data
                        this.showAddPage = true;
                        swal.close();
                    } else {
                        swal.fire("Error Ocurred!", res.msg, "warning");
                        this.record.year = null,
                        this.emptyFields();
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                    this.record.year = null,
                    this.emptyFields();
                });
            },
            setData(indexing) {// set paginated function
                this.emptyFields();
                this.record.year = indexing.year;
                this.record.id = indexing.id;
                this.quotaStatus = indexing.quota_status;
                this.current_quota = indexing.training_school ? indexing.training_school.quota: 0;// get quota 
                if(indexing && indexing.students) {
                    this.total_students = indexing.students.length;// get total no of students
                    let res = indexing.students;
                    this.students = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.students.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.students = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.students = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.students = this.paginatedItems.slice(from, to);
            },
            saveStudent() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    let data = this.record;
                    data.training_school_id = this.$store.state.user.school.id;
                    this.saveIndexing(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Indexing Updated!", res.msg, "success");
                            this.checkYear();
                        } else {
                            this.hideAddPage();
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            validatedData() {
                let data = this.record.student;
                data.year != null ? data.year : this.showInuptError('Year of Admission');
                data.matric_no != null ? data.matric_no : this.showInuptError('Matriculation No');
                data.gender != null ? data.gender : this.showInuptError('Gender');
                data.first_name != null ? data.first_name : this.showInuptError('First Name');
                data.surname != null ? data.surname : this.showInuptError('Surname');
                let status = data.surname && data.first_name && data.gender && data.matric_no && data.year;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            hideAddPage() {
                this.emptyFields();
                this.checkYear();
                this.showEditPage = false;
                return this.showAddPage = ! this.showAddPage;
            },
            emptyFields() {
                this.record.student = {
                    surname: null,
                    first_name: null,
                    other_names: null,
                    gender: null,
                    matric_no: null,
                    year: null,
                    email: null,
                    phone: null,
                    phone_code: null
                };
                this.tel = {
                    phone: null,
                    code: 'NG'
                };
                this.students = [];
                this.paginatedItems = [];
            },
            editRecord(row) {
                this.emptyFields();
                this.showEditPage = true;
                if(row) { 
                    this.record.student = row; 
                    this.loadPhoneNumber(row);// load phone no
                }
            },
            submitList() {// submit application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.submitListApiCall();// make api call
                    }
                });
            },
            submitListApiCall() {// delete
                this.preloader();// show loading
                let id = this.record && this.record.id ? this.record.id : false;
                if(id) {
                    this.submitIndexStudentsList(id).then((res) => {
                        this.checkYear();
                        if(res.status) {
                            swal.fire("List Submitted!", res.msg , "success");
                            setTimeout(() => {
                                window.location.href = '/indexing/previous-indexing-applications';
                            }, 2000);
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while proccessing, try again", "warning");
                        }
                    }).
                    catch(() => {
                        swal.fire("Network Failure", "Reload page and try again...", "warning");
                    });
                } else {
                    swal.fire("Error Occurred", "Reload page and try again...", "warning");
                    this.checkYear();
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.checkYear();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>