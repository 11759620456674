<template>
    <div class="container">
        <div class="row" v-if="! showEditPage">
            <div class="col-lg-12">
                <hr>
                <h3 class="text-uppercase text-center">
                    <em class="text-danger">{{ indexing.year }}</em>
                    Indexing Year's Students
                    <br>
                    <small class="text-uppercase text-center">
                        Current Allocated Quota -> {{ currentQuota }} 
                    </small>
                </h3>
                
                
            </div>

            <div class="col-lg-12 text-center" v-if="hasExceedLimit">
                <base-alert type="warning">
                    <span class="alert-inner--text">
                        <h3 class="text-white">
                            You have exceeded your School's Quota limit by -> {{ reminder }}, kindly remove students 
                            accordingly
                        </h3>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12" v-if="students && students.length > 0">
                <hr>
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="students"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th>Surname</th>
                                <th>First Name /<br> Other Names</th>
                                <th>Gender</th>
                                <th>Matriculation No <br> / Phone No</th>
                                <th>Admission Year</th>
                                <th>Email Address</th>
                                <th width="5%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <!-- v-if="checkInvalidStudent(row, index)" -->
                            <td class="budget">
                                {{ pagination.from+index+1}}
                            </td>
                            <td class="text-capitalize">
                                {{ row.surname ? row.surname : 'N/A' }}
                            </td>
                            <td class="text-capitalize">
                                {{ row.first_name ? row.first_name : 'N/A' }} <br>
                                <badge type="info">
                                    {{ row.other_names ? row.other_names : 'N/A' }}
                                </badge>
                            </td>
                            <td class="text-capitalize">{{ row.gender ? row.gender : 'N/A' }}</td>
                            <td>
                                {{ row.matric_no ? row.matric_no : 'N/A' }} <br>
                                <badge type="primary">
                                    {{ row.phone ? row.phone : 'N/A' }}
                                </badge>
                            </td>
                            <td>{{ row.year ? row.year : 'N/A'  }}</td>
                            <td>{{ row.email ? row.email : 'N/A'  }}</td>
                            <td>
                                <base-button
                                    type="info" size="sm"
                                    @click="editRecord(row, index)"
                                >
                                    <i class="fa fa-pen"></i> Edit
                                </base-button>
                                <base-button
                                    type="danger" size="sm"
                                    @click="deleteRecord(index)"
                                >
                                    <i class="fa fa-trash"></i> Remove
                                </base-button>
                            </td>

                            </template>

                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>

                </div>
            </div>

            <div class="col-lg-12">
                <hr>
                <base-button
                    type="danger"
                    size="lg"
                    @click="removeBulkUpload()"
                >
                    <i class="fa fa-times"></i> Go Back
                </base-button>

                <base-button
                    class="right"
                    type="success"
                    size="lg"
                    @click="uploadStudents()"
                    :disabled="hasExceedLimit"
                >
                    <i class="fa fa-upload"></i> Upload Now
                </base-button>
            </div>
        </div>

        <div class="col-lg-12 text-uppercase text-center" v-if="showEditPage">
            <hr>
            <h3>
                Edit Student Details for Indexing Year 
                (<em class="text-danger">{{ indexing.year }}</em>)
            </h3>
            <hr>
        </div>

        <AddStudent 
            :record="record"
            :years="years"
            :tel="tel"
            :updatePhoneNumber="updatePhoneNumber"
            :showEditPage="showEditPage"
            v-if="showEditPage"
        />

        <div class="col-lg-12" v-if="showEditPage">
            <hr>
            <base-button
                type="success"
                @click="updateStudent()"
            >
                <i class="fa fa-save"></i>
                {{ isloading ? 'Please Wait...' : 'Update Student' }}
            </base-button>

            <base-button
                type="danger"
                class="right"
                @click="hideEditPage()"
            >
                <i class="fa fa-times"></i> Cancel
            </base-button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';
    import Badge from '../../../components/Badge.vue';
    import AddStudent from './AddStudent.vue';

    export default {
        components: { 
            Badge,
            AddStudent
        },
        name: 'BulkStudentsUpload',
        props: ["records", 'indexing', 'currentQuota', 'loadPhoneNumber', 'years', 'checkYear', 'removeBulkUpload'],
        data() {
            return {
                paginatedItems: [],
                students: [],
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showEditPage: false,
                record: {
                    year: null,
                    student: {
                        surname: null,
                        first_name: null,
                        other_names: null,
                        gender: null,
                        matric_no: null,
                        year: null,
                        email: null,
                        phone: null,
                        phone_code: null
                    }
                },
                tel: {
                    phone: null,
                    code: 'NG'
                },
                currentIndex: null,
                isloading: false
            }
        },
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        },
        computed: {
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            reminder() {// get reminder of the allocation
                if(this.currentQuota) {
                    let total = this.records.length;
                    return Math.abs(parseInt(this.currentQuota) - parseInt(total));
                } else {
                    return 'N/A';
                }
            },
            hasExceedLimit() {// has exceed quota limit
                return this.paginatedItems.length > this.currentQuota;
            }
        },
        methods: {
            ...mapActions(['uploadBatchIndexing']),
            setData() {
                let res = this.records;
                this.students = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    for (let index = 1; index <= perPage; index++) {
                        this.students.push(this.paginatedItems[index]);
                    }
                } else {
                    this.students = this.paginatedItems;
                }
                this.record.year = this.indexing ? this.indexing.year : null;
            },
            paginate() {// paginate function
                this.students = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.students = this.paginatedItems.slice(from, to);
            },
            uploadStudents() {
                this.isloading = true;
                this.preloader();// show loading
                if(this.paginatedItems.length > 0 && this.indexing && this.indexing.id) {// update
                    let data = {
                        training_school_id : this.$store.state.user.school.id,
                        indexing_id : this.indexing.id,
                        records : this.paginatedItems
                    };
                    this.uploadBatchIndexing(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Students Uploaded!", res.msg, "success");
                            this.removeBulkUpload();// remove bulk students
                            this.checkYear();// refresh page
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    let msg = "No records was uploaded, kindly reload page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                }
            },
            updateStudent() {// update a particular student record
                this.isloading = true;
                let student = this.record.student;
                this.paginatedItems[this.currentIndex] = student;
                this.currentIndex = null;
                this.showEditPage = false;
                return this.isloading = false;
            },
            deleteRecord(index) {// remove student
                return this.paginatedItems.splice(index, 1);
            },
            editRecord(row, index) {// edit student record
                this.emptyFields();
                this.showEditPage = true;
                if(row) { 
                    this.currentIndex = index;
                    this.record.student = {
                        surname: row.surname,
                        first_name: row.first_name,
                        other_names: row.other_names,
                        gender: row.gender.toLowerCase(),
                        matric_no: row.matric_no,
                        year: row.year,
                        email: row.email,
                        phone: row.phone,
                        phone_code: null
                    };
                    this.loadPhoneNumber(row);// load phone no
                }
            },
            hideEditPage() {// hide edit page
                return this.showEditPage = false;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.student.phone = event.formattedNumber;
                    this.record.student.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            emptyFields() {
                this.currentIndex = null;
                this.record.student = {
                        surname: null,
                        first_name: null,
                        other_names: null,
                        gender: null,
                        matric_no: null,
                        year: null,
                        email: null,
                        phone: null,
                        phone_code: null
                };
                this.tel = {
                    phone: null,
                    code: 'NG'
                };
                this.showEditPage = false;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
            // checkInvalidStudent(record, index) {// check if inputs are valid for a student
            // console.log(record)
            //     let check = record && record.surname && record.first_name && record.gender && record.matric_no
            //             && ((record.gender.toLowerCase() == 'male') || (record.gender.toLowerCase() == 'female')) 
            //             && record.year && this.isNumber(record.year);
            //     if(! check) {// remove student
            //         return this.paginatedItems.splice(index, 1);
            //     }
            //     return true;
            //     return true;
            // },
            // isNumber(n) { 
            //     return !isNaN(parseFloat(n)) && !isNaN(n - 0);
            // }
        },
        created() {
            this.setData();
        }
    }
</script>
<style scoped>
    .right {
        float: right;
    }
</style>